import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Campaign, Organization, Review } from "../types";
import { datasource } from "../datasource/datasource";
import { CampaignButton } from "../components/campaignButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import "./dashboard.css";
import { SideBar, SideBarItemData } from "../components/sidebar";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { ReviewCard } from "../components/reviewCard";
import { DashBoardTopBar } from "../components/dashboardTopBar";
import { Drawer, Snackbar } from "@mui/material";
import { DashboardContentSection } from "../components/dashboardContentSection";
import { Loading } from "../recorderComponents/loading";
import {
  CampaignFrontend,
  campaignStub,
  organizationFrontend,
} from "../syncedTypes";
import useCampaigns from "../hooks/useCampaigns";
import useOrganziations from "../hooks/useOrganizations";
import useUser from "../hooks/useUser";
import {
  ContextMenu,
  ContextMenuContext,
  Cords,
} from "../components/ContextMenu";
import { CampaingModal } from "../components/CampaignModal";
import { DeleteCampaignModal } from "../components/DeleteCampaignModal";

export const DashboardContext = createContext<{
  updateReviews: (reviews: Review[]) => void;
  openSnackBar: () => void;
  isExpanded: boolean;
  setIsExpanded: (newVal: boolean) => void;
}>({
  updateReviews: (reviews: Review[]) => undefined,
  openSnackBar: () => undefined,
  isExpanded: false,
  setIsExpanded: (newVal: boolean) => undefined,
});

export const VideoPlayerContext = createContext<{
  videoPlayingHash: string;
  updatePlayingHash: (hash: string) => void;
}>({
  updatePlayingHash: (hash: string) => undefined,
  videoPlayingHash: "",
});

export const CampaignModalContext = createContext<{
  isOpen: boolean;
  // setIsOpen: (newVal: boolean) => void;
  campaignToEdit: CampaignFrontend | null;
  setCampaignToEdit: (campaign: CampaignFrontend | null) => void;
  setOpenNewCampaign: () => void;
  setOpenEditCampaign: (campaignToEdit: CampaignFrontend) => void;
  closeModal: () => void;
}>({
  isOpen: false,
  // setIsOpen: (newVal: boolean) => undefined,
  campaignToEdit: null,
  setCampaignToEdit: (campaign: CampaignFrontend | null) => undefined,
  setOpenNewCampaign: () => undefined,
  setOpenEditCampaign: (campaignToEdit: CampaignFrontend) => undefined,
  closeModal: () => undefined
});

export const DeleteCampaignModalContext = createContext<{
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  campaignId: number;
  setCampaignId: (num: number) => void
}>({
  isOpen: false,
  setIsOpen: (newVal: boolean) => undefined,
  campaignId: 0,
  setCampaignId: (num: number) => undefined
});

export const Dashboard = () => {
  const nav = useNavigate();
  const { getToken, userId, isLoaded } = useAuth();

  const currentCampaigns = useCampaigns((state) => state.allCampaigns);
  const activeCampaign = useCampaigns((state) => state.selectedCampaign);
  const setCurrentCampaigns = useCampaigns((state) => state.setCampaigns);
  const setActiveCampaign = useCampaigns((state) => state.setSelectedCampaign);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [deleteCampaginModalIsOpen, setDeleteCampaginModalIsOpen] = useState<boolean>(false);
  const [campaignIdToDelete, setCampaignIdToDelete] = useState<number>(0);
  const [campaignModalIsOpen, setCampaignModalIsOpen] =
    useState<boolean>(false);
  const [campaignToEdit, setCampaignToEdit] = useState<CampaignFrontend | null>(
    null
  );

  const setOpenNewCampaign = useCallback(() => {
    setCampaignToEdit(null);
    setCampaignModalIsOpen(true);
  }, [setCampaignToEdit, setCampaignModalIsOpen]);

  const setOpenEditCampaign = useCallback(
    (campaign: CampaignFrontend) => {
      setCampaignToEdit(campaign);
      setCampaignModalIsOpen(true);
    },
    [setCampaignToEdit, setCampaignModalIsOpen]
  );

  const closeModal = useCallback(() => {
    setCampaignModalIsOpen(false);
  }, [setCampaignModalIsOpen, setCampaignToEdit]);

  const setUserOrganizations = useOrganziations(
    (state) => state.setAllOrganizations
  );

  useEffect(() => {
    setIsExpanded(false);
  }, [activeCampaign]);

  const setActiveOrg = useOrganziations((state) => state.setActiveOrganization);
  const [isLoading, setIsLoading] = useState<boolean>(!currentCampaigns.length);
  const [firstLoadStubs, setFirstLoadStubs] = useState<campaignStub[]>([]);

  const [contextMenuIsOpen, setContextMenuIsOpen] = useState<boolean>(false);
  const [contextMenuOptions, setContextMenuOptions] = useState<JSX.Element[]>(
    []
  );
  const [contextMenuCords, setContextMenuCords] = useState<Cords>({
    x: 0,
    y: 0,
  });
  const closeContextMenu = useCallback(() => {
    setContextMenuIsOpen(false);
    setContextMenuOptions([]);
    setContextMenuCords({ x: 0, y: 0 });
  }, [setContextMenuIsOpen, setContextMenuOptions, setContextMenuCords]);
  const openContextMenu = useCallback(
    (cords: Cords, options: JSX.Element[]) => {
      console.log("Open menu");
      setContextMenuCords(cords);
      setContextMenuOptions(options);
      setContextMenuIsOpen(true);
    },
    [setContextMenuIsOpen, setContextMenuOptions, setContextMenuCords]
  );

  const [videoPlayingHash, setVideoPlayingHash] = useState<string>("");

  const setUser = useUser((state) => state.setUser);

  useEffect(() => {
    if (isLoaded && userId) {
      nav("/dashboard");
    } else if (isLoaded && !userId) {
      nav("/set-up");
    }
  }, [userId, isLoaded]);

  const openSnackBar = useCallback(() => {
    setSnackBarOpen(true);
    setTimeout(() => setSnackBarOpen(false), 3000);
  }, [setSnackBarOpen]);

  const displayedReviews = useMemo(() => {
    return activeCampaign?.reviews ?? [];
  }, [activeCampaign]);

  const { height } = useScreenDimensions();

  useEffect(() => {
    if (currentCampaigns.length) {
      return;
    } else {
      setIsLoading(true);
      const setup = async () => {
        const token = await getToken();
        const userOrgs = await datasource.getUserOrgs(token as string);
        if (!userOrgs.length) {
          nav("/set-up");
          return;
        }
        datasource.getUser(token as string).then((user) => setUser(user));
        const initialData = await datasource.getInitialData(
          userOrgs[0].id,
          token as string
        );
        const campaignPromMap = initialData.campaignStubs.map((el) =>
          datasource.getCampaignInfo(el.id, token as string)
        );

        setUserOrganizations(userOrgs);
        setActiveOrg(userOrgs[0]);
        setCurrentCampaigns([initialData.firstCampaign]);
        setActiveCampaign(initialData.firstCampaign);
        setFirstLoadStubs(initialData.campaignStubs);
        setIsLoading(false);

        const allCampaigns = await Promise.all(campaignPromMap);
        setCurrentCampaigns(allCampaigns);
      };
      setup();
    }
  }, []);

  const sideBarItems = useMemo(() => {
    return currentCampaigns.map((el) => {
      return {
        ...el,
        isSelected: el.id === activeCampaign?.id,
      };
    });
  }, [currentCampaigns, activeCampaign]);

  const setActiveSidebarItem = useCallback(
    (sidebarItem: SideBarItemData) => {
      const [selectedItem] = currentCampaigns.filter(
        (el) => el.id === sidebarItem.id
      );
      setActiveCampaign(selectedItem);
    },
    [setActiveCampaign, currentCampaigns]
  );

  const updateReviews = useCallback((reviews: Review[]) => {
    // setReviews(reviews)
  }, []);

  return (
    <ContextMenuContext.Provider
      value={{
        setOpen: openContextMenu,
        isOpen: contextMenuIsOpen,
        closeMenu: closeContextMenu,
        options: contextMenuOptions,
        cords: contextMenuCords,
      }}
    >
      <DashboardContext.Provider
        value={{ updateReviews, openSnackBar, isExpanded, setIsExpanded }}
      >
        <VideoPlayerContext.Provider
          value={{ videoPlayingHash, updatePlayingHash: setVideoPlayingHash }}
        >
          <CampaignModalContext.Provider
            value={{
              isOpen: campaignModalIsOpen,
              campaignToEdit,
              setCampaignToEdit,
              setOpenNewCampaign,
              setOpenEditCampaign,
              closeModal
            }}
          >
            <DeleteCampaignModalContext.Provider value={{isOpen: deleteCampaginModalIsOpen, setIsOpen: setDeleteCampaginModalIsOpen, campaignId: campaignIdToDelete, setCampaignId: setCampaignIdToDelete}}>
            <Drawer
              anchor="bottom"
              open={isLoading}
              PaperProps={{ sx: { backgroundColor: "#FFF8F0" } }}
              transitionDuration={{ exit: 500, enter: 500 }}
            >
              <Loading />
            </Drawer>
            <div
              className="dashboardPage"
              style={{ minHeight: height, width: "100%" }}
            >
              <DeleteCampaignModal />
              <CampaingModal />
              <ContextMenu />
              <SideBar />
              <div
                className="mainPanelContainer"
                style={{
                  marginLeft: "1rem",
                  backgroundColor: "#FFFFFF",
                  marginTop: "1rem",
                  borderRadius: 16,
                  marginBottom: "2rem",
                }}
              >
                <DashBoardTopBar />
                <DashboardContentSection reviews={displayedReviews} />
              </div>
              <Snackbar open={snackBarOpen} message="Copied to Clipboard" />
            </div>
            </DeleteCampaignModalContext.Provider>
          </CampaignModalContext.Provider>
        </VideoPlayerContext.Provider>
      </DashboardContext.Provider>
    </ContextMenuContext.Provider>
  );
};
