import { AddNewCampaignRequestBody, CampaignFrontend, GetAllUsersOrganizationsResponse, initialSetupResponse, organizationFrontend, RecorderFrontendData, signUpNewOrgAndUserBody, User } from "../syncedTypes";
import { Campaign, RecorderInfoResponse, Review } from "../types";

const currentBackend = (process.env.NODE_ENV === 'development') ? 'http://localhost:4001' :'https://backend.speakingof.me'

const makeNullIfLengthIsZero = (st: string | void) => (!st || st.length) ? st : null;

class Datasource {
    async internalGetFetch(url: string, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const data = res.json();
        return data;
    }

    async internalGetFetchNoJson(url: string, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return res;
    }

    async noAuthInternalGetFetch(url: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
        });

        const data = res.json();
        return data;
    }

    async noAuthInternalPost(url: string, body: any): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body)
        })

        const data = res.json();
        return data;
    }

    async internalPost(url: string, body: any, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body)
        });

        const data = res.json();
        return data;
    }

    async internalPut(url: string, body: any, token: string): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(body)
        });

        const data = res.json();
        return data;
    }

    async putNoAuth(url: string, body: any): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(body)
        });

        const data = res.json();
        return data;
    }

    async internalDelete(url: string, token: string, body?: any,): Promise<any> {
        const res = await fetch(`${currentBackend}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: JSON.stringify(body ?? {})
        });

        const data = res.json();
        return data;
    }

    async getRecordData(hash: string, isInvite: boolean): Promise<RecorderInfoResponse> {
        const path = isInvite ? '/recorder/invite/' + hash : '/recorder/c/' + hash
        return this.noAuthInternalGetFetch(path)
    }

    async getCurrentCampaigns(token: string): Promise<Campaign[]> {
        return this.internalGetFetch('/campaign', token)
    }

    async getAllReviews(token: string): Promise<Review[]> {
        return this.internalGetFetch('/campaign/reviews', token)
    }

    async addNewCampaign(newCampaignName: string, questions: string[], orgId: number, token: string) {
        const body: AddNewCampaignRequestBody = {campaignName: newCampaignName, questions };
        return this.internalPost('/campaign/' + orgId, body, token);
    }

    async editCampaign(newCampaignName: string, questions: string[], orgId: number, campaignId: number, token: string) {
        const body: AddNewCampaignRequestBody = {campaignName: newCampaignName, questions };
        return this.internalPut(`/campaign/${orgId}/${campaignId}`, body, token);
    }

    async addNewReview(reviewerName: string, reviewerEmail: string | null, reviewerPhoneNumber: string | null, customPrompt: string | null, campaignId: number, token: string) {
        if (reviewerEmail === '') {
            reviewerEmail = null;
        }
        if (reviewerPhoneNumber === '') {
            reviewerPhoneNumber = null;
        }

        if (customPrompt === '') {
            customPrompt = null;
        }

        const body = {
            reviewerName,
            reviewerEmail,
            reviewerPhoneNumber,
            campaignId,
            customPrompt
        }

        return this.internalPost('/campaign/reviews', body, token)
    }

    async submitVideo(campaignId: number, reviewId: number, questionId: number, blob: Blob) {
        const path = `/recorder/${campaignId}/${reviewId}/${questionId}`;
        const formData = new FormData();
        const type = blob.type;
        const extension = type?.split?.(';')?.[0]?.split?.('/')?.[1] ?? 'mp4'
        formData.append('file', blob, `${reviewId}-${questionId}` + '.' + extension)
        return fetch(currentBackend + path, {
            method: 'POST',
            body: formData
        })
    }

    async sealVideo(campaignId: number, reviewId: number, questionId: number) {
        const path = `/recorder/seal/${campaignId}/${reviewId}/${questionId}`;
        return fetch(currentBackend + path)
    }

    async getVideo(orgId: number, hash: string, token: string) {
        const file = await this.internalGetFetchNoJson(`/reviews/${orgId}/${hash}/download`, token);
        return file
    }

    async registUserAndOrg(details: signUpNewOrgAndUserBody, token: string) {
        return this.internalPost('/user/sign-up-new-org', details, token)
    }

    async editReview(hash: string, name: string, email: string | void, phoneNumber: string | void, customPrompt: string | void, token: string) {
        return this.internalPut('/campaign/review/' + hash, {reviewerName: name, reviewerEmail: makeNullIfLengthIsZero(email), reviewerPhoneNumber: makeNullIfLengthIsZero(phoneNumber), customPrompt: makeNullIfLengthIsZero(customPrompt)}, token)
    }

    async deleteReview(hash: string, token: string) {
        return this.internalDelete('/campaign/review/' + hash, token)
    }

    async getUserOrgs(token: string): Promise<organizationFrontend[]> {
        const orgs: GetAllUsersOrganizationsResponse = await this.internalGetFetch('/organization/user', token);
        return orgs.orgs
    }

    async getInitialData(orgId: number, token: string) {
        const initialData: initialSetupResponse = await this.internalGetFetch('/campaign/on-first-load/' + orgId, token);
        return initialData;
    }

    async getCampaignInfo(campaignId: number, token: string): Promise<CampaignFrontend> {
        return this.internalGetFetch('/campaign/' + campaignId, token);
    }

    async getRecorderData(hash: string): Promise<RecorderFrontendData> {
        return this.noAuthInternalGetFetch('/recorder/' + hash);
    }

    async createReviewId(campaignId: number): Promise<number> {
        const data = await this.noAuthInternalPost('/recorder/new', {campaignId});
        return data.reviewId;
    }

    async setCampaignActiveStatus(campaignId: number, orgId: number, newStatus: boolean, token: string): Promise<CampaignFrontend> {
        return this.internalPut(`/campaign/${orgId}/${campaignId}/setStatus`, {setToActive: newStatus}, token)
    }

    async setReviewerName(campaignId: number, reviewId: number, name: string, email: string) {
        return this.putNoAuth(`/recorder/${campaignId}/${reviewId}/name`, {name, email});
    }

    async updateReviewName(orgId: number, campaignId: number, reviewId: number, name: string, token: string) {
        return this.internalPut(`/reviews/${orgId}/${campaignId}/${reviewId}/name`, {newName: name}, token)
    }

    async updateNotes(orgId: number, campaignId: number, reviewId: number, notes: string, token: string) {
        return this.internalPut(`/reviews/${orgId}/${campaignId}/${reviewId}/notes`, {notes: notes}, token)
    }

    async deleteCampaign(orgId: number, campaignId: number, token: string) {
        return this.internalDelete(`/campaign/${orgId}/${campaignId}`, token)
    }

    async getUser(token: string) {
        const {user} = await this.internalGetFetch('/user/getUser', token);
        return user as User
    }

    async getStripeSessionSecretForFullPackage(token: string) {
        const res = await this.internalPost('/payments/create-checkout-session/full', {}, token);
        return res.clientSecret
    }

    async getStripeSessionSecretForLitePackage(token: string) {
        const res = await this.internalPost('/payments/create-checkout-session/lite', {}, token);
        return res.clientSecret
    }
}

export const datasource = new Datasource();