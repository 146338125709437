import { create } from 'zustand';
import { organizationFrontend, ReviewFrontend } from '../syncedTypes';

interface OrganizationsStore {
  organizations: organizationFrontend[],
  activeOrganization: organizationFrontend | null;
  setActiveOrganization: (org: organizationFrontend) => void;
  setAllOrganizations: (orgs: organizationFrontend[]) => void;
}

const useOrganziations = create<OrganizationsStore>((set) => ({
  organizations: [],
  activeOrganization: null,
  setActiveOrganization: (org: organizationFrontend) => set({ activeOrganization: org }),
  setAllOrganizations: (orgs: organizationFrontend[]) => set({ organizations: orgs }),
}));

export default useOrganziations;
