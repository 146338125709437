import * as motion from "motion/react-client";
import Webcam from "react-webcam";
import { AnimatePresence } from "motion/react";
import { RecorderPill } from "../components/recorderPill";
import { Ref } from "react";
import playButton from "../resources/playbutton.svg";

const mediaSettings = {
  facingMode: "user",
  width: { min: 640, ideal: 3840 },
  height: { min: 480, ideal: 2160 },
};

export const RecorderRightBar = ({
  renderMobileLayout,
  questionNumber,
  recordedChunks,
  webcamRef,
  overLayText,
  toggleVideoPlaying,
  videoRef,
  videoUrl,
  setVideoIsPlaying,
  isRecording,
  videoIsPlaying,
  buttons
}: {
  renderMobileLayout: boolean;
  questionNumber: number;
  recordedChunks: any[];
  webcamRef: Ref<any>,
  overLayText: string | null,
  toggleVideoPlaying: () => void,
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
  videoUrl: string,
  setVideoIsPlaying: (newVal: boolean) => void,
  isRecording: boolean,
  videoIsPlaying: boolean,
  buttons: JSX.Element[]
}) => {
  return (
    <div
      style={{
        width: !renderMobileLayout ? "60%" : "100%",
        backgroundColor: "white",
        borderRadius: 16,
        padding: "2rem",
      }}
    >
      <div style={{ position: "relative" }}>
        <div style={{ opacity: recordedChunks[questionNumber] ? 0 : 1 }}>
          <Webcam
            audio={true}
            muted={true}
            ref={webcamRef}
            videoConstraints={mediaSettings}
            style={{ borderRadius: 16 }}
          />
          <AnimatePresence>
            {overLayText ? (
              <motion.div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: "rgba(0,0,0,0)",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <motion.div
                  layout
                  style={{
                    color: "white",
                    fontSize: "2rem",
                    borderRadius: 16,
                    fontWeight: 500,
                    background: "black",
                    padding: ".5rem",
                    minWidth: "4rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {overLayText}
                </motion.div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: recordedChunks[questionNumber] ? 1 : 0,
            top: 0,
            left: 0,
          }}
          onClick={toggleVideoPlaying}
        >
          <video
            ref={videoRef}
            controls={false}
            src={videoUrl}
            onEnded={() => setVideoIsPlaying(false)}
            style={{ borderRadius: 16 }}
          />
          {!videoIsPlaying ? (
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
              }}
            >
              <img style={{ width: "4rem", height: "4rem" }} src={playButton} />
            </div>
          ) : null}
        </div>
        <RecorderPill isRecording={isRecording} />
      </div>

      {questionNumber >= 0 ? (
        <motion.div
          style={{ position: "relative" }}
          initial={{ opacity: 0, left: 250 }}
          animate={{ opacity: 1, left: 0 }}
        >
          {buttons}
        </motion.div>
      ) : null}
    </div>
  );
};
