import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { SidebarItem } from "./sidebarItem";
import { Campaign } from "../types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useCallback, useContext, useState } from "react";
import { CampaignFrontend } from "../syncedTypes";
import useCampaigns from "../hooks/useCampaigns";
import { UserButton } from "@clerk/clerk-react";
import useUser from "../hooks/useUser";
import next from "../resources/next.svg";
import './sidebar.css';
import { StandardButton } from "./standardButton";
import { CampaignModalContext } from "../Pages/dashboard";

export interface SideBarItemData extends CampaignFrontend {
  isSelected: boolean;
}

export const SideBar = () => {
  const campaigns = useCampaigns((state) => state.allCampaigns);
  const setAllReviews = useCampaigns((state) => state.setCampaignToViewAll);

  const {setOpenNewCampaign} = useContext(CampaignModalContext)

  const user = useUser((state) => state.user);

  const { height } = useScreenDimensions();

  const heightInRem = height / 16;

  return (
    <div style={{ minWidth: "20rem" }}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 16,
          height: "5rem",
          marginTop: "1rem",
          marginLeft: "1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="ml-4 flex justify-between" style={{ width: "100%" }}>
          <UserButton />
          <div className="flex flex-col ml-6 mr-8">
            <div style={{ fontWeight: 500, fontSize: 18 }}>
              {user?.firstName + " " + user?.lastName}
            </div>
            <div style={{ fontSize: 14, color: "#899091" }}>{user?.email}</div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 16,
          marginTop: "1rem",
          marginLeft: "1rem",
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          minHeight: `${heightInRem - 9}rem`,
          position: 'relative'
        }}
      >
        <div style={{ borderRadius: 24, width: "100%" }}>
          <div
            style={{
              borderRadius: 24,
              borderWidth: 1,
              borderColor: "#F1F1F1",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: '1rem',
              paddingRight: '1rem',
              height: '3rem',
              alignItems: 'center',
              marginTop: '1rem',
              marginLeft: '1rem',
              marginRight: '1rem',
              marginBottom: '2rem'
            }}
            className="allReviewsButton"
            onClick={setAllReviews}
          >
            <span>All Reviews</span>
            <img src={next} style={{height: '.7rem'}} />
          </div>
          {campaigns.map((el, i) => {
            return <SidebarItem campaign={el} index={i} key={el.hash} />;
          })}
          <div style={{padding: '1rem'}}>
          <StandardButton buttonColor={'#1F1F1F'} buttonText="+  Add New Campaign" onClick={setOpenNewCampaign} customStyles={{fontSize: '1rem'}}/>
          <div style={{position: 'absolute', bottom: '1rem', display: 'flex', justifyContent: 'center', width: '100%', left:0}}>
            <img src={'/logo.svg'} />
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};
