import { useCallback, useContext, useMemo } from "react";
import { DashboardContext } from "../Pages/dashboard";
import useCampaigns from "../hooks/useCampaigns";
import { ThumbnailCircle } from "./ThumbnailCircle";
import * as motion from "motion/react-client";
import { AnimatePresence } from "motion/react";
import backChevron from '../resources/backChevron.svg'
import { useAutoAnimate } from '@formkit/auto-animate/react'

export const DashBoardTopBar = () => {
  const activeCampaign = useCampaigns((state) => state.selectedCampaign);
  const allCampaigns = useCampaigns((state) => state.allCampaigns);
  const index = useMemo(() => {
    return allCampaigns.findIndex((el) => el.hash === activeCampaign?.hash);
  }, [activeCampaign]);

  const { isExpanded, setIsExpanded } = useContext(DashboardContext);

  const onBackClick = () => setIsExpanded(false);

  const [parentRef] = useAutoAnimate();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "2rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }} ref={parentRef}>
          {isExpanded ? (
            <div
              style={{
                height: "1.5rem",
                width: "4rem",
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: '1rem',
                marginLeft: '1rem',
                cursor: 'pointer'
              }}
              onClick={onBackClick}
            >
              <div><img src={backChevron} /></div>
              <div>Back</div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            {!isExpanded && (
              <ThumbnailCircle
                index={index}
                letter={activeCampaign?.name[0].toUpperCase() || "C"}
                remSize={4}
              />
            )}
            <motion.span layout style={{ marginLeft: "1rem", fontWeight: 500, fontSize: isExpanded ? '1.5rem' : '1rem', width: 400 }}>
              {activeCampaign?.name}
            </motion.span>
          </div>
      </div>
    </div>
  );
};
