import { useCallback, useEffect, useState } from "react";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { StandardButton } from "../components/standardButton";
import { Organization } from "../types";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { signUpNewOrgAndUserBody } from "../syncedTypes";
import { createOnChangeHandler } from "../lib/helpers";
import { StandardInput } from "../components/standardInput";

const basicSelectInputStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingTop: ".75rem",
  paddingBottom: ".75rem",
  gap: 36,
  backgroundColor: "#F7F7F7",
  borderRadius: 24,
  marginTop: '.5rem',
  width: '100%'
};

const fieldOptions = [
  "Select Industry",
  "Technology",
  "Finance",
  "Healthcare",
  "Education",
  "Retail",
  "Other",
];
const businessSizeOptions = [
  "Select Size",
  "1-10",
  "11-100",
  "101-1000",
  "1000+",
];
const modesOfFindingOptions = [
  "Select Referral Source",
  "Search Engine",
  "Social Media",
  "Freind or Colleague",
  "Advertizement",
  "I was a reviewer",
  "Other",
];

export const RegisterOrg = () => {
  const { height } = useScreenDimensions();
  const { getToken } = useAuth();
  const nav = useNavigate();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [industry, setIndustry] = useState<string>(fieldOptions[0]);
  const [businessSize, setBusinessSize] = useState<string>(
    businessSizeOptions[0]
  );
  const [modesOfFinding, setModesOfFinding] = useState<string>(
    modesOfFindingOptions[0]
  );

  const firstNameChange = createOnChangeHandler(setFirstName);
  const lastNameChange = createOnChangeHandler(setLastName);
  const businessNameChange = createOnChangeHandler(setBusinessName);
  const industryChange = createOnChangeHandler(setIndustry);
  const businessSizeChange = createOnChangeHandler(setBusinessSize);
  const modeOfFindingChange = createOnChangeHandler(setModesOfFinding);

  const onSubmit = useCallback(async () => {
    const token = await getToken();
    const details: signUpNewOrgAndUserBody = {
      orgName: businessName,
      planType: 1,
    };
    const complete = await datasource.registUserAndOrg(
      details,
      token as string
    );
    nav("/plans");
  }, [businessName, getToken]);

  // useEffect(() => {
  //     const checkIfOrgExists = async () => {
  //         const token = await getToken();
  //         const orgs = await datasource.getUserOrgs(token as string);
  //         if (orgs.length) {
  //             nav('/dashboard')
  //         }
  //     }

  //     checkIfOrgExists();
  // }, [])

  return (
    <div
      style={{
        height,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          minWidth: "24rem",
          width: "50%",
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: 16,
        }}
      >
        <div className="flex justify-center">
          <img src={"/logo.svg"} />
        </div>
        <div className="mt-6" />
        <div className="flex justify-center flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl tracking-tight pb-4">
            Welcome to Speaking Of Me
          </h2>
          <div className="mt-4" />
          <h4 style={{ fontWeight: 400, fontSize: 14, color: "#BCBCBC" }}>
            Tell us about yourself
          </h4>
        </div>
        <div className="mb-6" />
        <div className="flex justify-evenly mb-6 gap-4">
          <StandardInput
            label="First Name"
            onChange={firstNameChange}
            placeHolder="First name"
            value={firstName}
          />
          <StandardInput
            label="Last Name"
            onChange={lastNameChange}
            placeHolder="Last name"
            value={lastName}
          />
        </div>
        <StandardInput
            label="Business Name"
            onChange={businessNameChange}
            placeHolder="Business name"
            value={businessName}
          />
        <div className="flex justify-between mt-6" style={{ gap: "1rem" }}>
          <div style={{ flex: 1 }}>
            <span>Industry</span>
            <select
              style={basicSelectInputStyles}
              value={industry}
              label="Industry"
              //   @ts-ignore
              onChange={industryChange}
            >
              {fieldOptions.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>
          <div style={{ flex: 1 }}>
            <span>Size Of Organization</span>
            <select
              style={basicSelectInputStyles}
              value={businessSize}
              label="businessSize"
              placeholder="What Industry is your Business a Part of?"
              //   @ts-ignore
              onChange={businessSizeChange}
            >
              {businessSizeOptions.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>
          <div style={{ flex: 1 }}>
            <span>Referral Source</span>
            <select
              style={basicSelectInputStyles}
              value={modesOfFinding}
              label="Industry"
              placeholder="What Industry is your Business a Part of?"
              //   @ts-ignore
              onChange={modeOfFindingChange}
            >
              {modesOfFindingOptions.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-6"/>
        <StandardButton buttonText="Save" onClick={onSubmit} />
      </div>
    </div>
  );
};
