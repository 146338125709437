export const Pill = ({
  backgroundColor,
  textColor,
  text,
  width,
  style
}: {
  backgroundColor: string;
  textColor: string;
  text: string;
  width?: number | string;
  style?: React.CSSProperties
}) => {
  const s = width ? { width } : {};
  return (
    <div
      style={{
        backgroundColor,
        color: textColor,
        borderRadius: 16,
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        ...s,
        ...style
      }}
    >
      {text}
    </div>
  );
};
