import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { Drawer } from "@mui/material";
import { Loading } from "../recorderComponents/loading";
import { datasource } from "../datasource/datasource";
import NumberCircle from "../components/NumberCirlcle";
import { StandardButton } from "../components/standardButton";
import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";
import Webcam from "react-webcam";
import { RecorderPill } from "../components/recorderPill";
import { CollectNameModal } from "../components/collectNameModal";
import { isIOS, isMobile } from "react-device-detect";
import { RecorderLeftBar } from "./recorderLeftBar";
import { RecorderRightBar } from "./recorderRightBar";

const mimeType = "video/mp4";

export interface RecorderCampaignInfo {
  hash: string;
  id: number;
  orgName: string;
  questions: Question[];
}

export interface Question {
  text: string;
  orderIndex: number;
  id: number;
}

interface RecorderPageParams {
  id: string;
}

export const RecorderContext = createContext<{
  questionNumber: number;
  setQuestionNumber: (num: number) => void;
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  reviewId: number;
  campaignInfo: RecorderCampaignInfo | null;
  nameSectionComplete: boolean;
  setNameSectionComplete: (newVal: boolean) => void;
}>({
  questionNumber: -1,
  setQuestionNumber: (num: number) => undefined,
  isOpen: false,
  setIsOpen: (newVal: boolean) => undefined,
  reviewId: 0,
  campaignInfo: null,
  nameSectionComplete: false,
  setNameSectionComplete: (newVal: boolean) => undefined,
});

export const RecorderPage = () => {
  // @ts-expect-error
  const param: RecorderPageParams = useParams();

  const { width, height } = useScreenDimensions();

  const timeoutRef = useRef<any>(null);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [questionNumber, setQuestionNumber] = useState<number>(-1);
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
  const [campaignInfo, setCampaignInfo] = useState<RecorderCampaignInfo | null>(
    null
  );
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [overLayText, setOverlayText] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);
  const [finalUploadeStarted, setFinalUploadStarted] = useState<boolean>(false);
  const [finalUploadComplete, setFinalUploadComplete] =
    useState<boolean>(false);

  const [reviewId, setReviewId] = useState<number>(0);

  const [nameCollectionModalOpen, setNameCollectionModalOpen] =
    useState<boolean>(false);
  const [nameCollectionComplete, setNameCollectionComplete] =
    useState<boolean>(false);

  const [showRight, setShowRight] = useState<boolean>(false);

  const videoRef = useRef<null | HTMLVideoElement>(null);
  const videoInputRef = useRef<HTMLInputElement | null>(null);

  const renderMobileLayout = useMemo(
    () => isMobile || width <= 768,
    [isMobile, width, height]
  );

  useEffect(() => {
    // && !isIOS
    if (recordedChunks[questionNumber]) {
      const blob = new Blob(recordedChunks[questionNumber], {
        type: "video/mp4",
      });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
    }
    setVideoIsPlaying(false);
  }, [recordedChunks, questionNumber]);

  const hasRecordingForThisQuestion = useMemo(
    () => !!recordedChunks[questionNumber],
    [recordedChunks, questionNumber]
  );

  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const goToNextQuestion = useCallback((isFinalVideo?: boolean) => {
    if (!isFinalVideo) {
      setShowRight(false);
    }
    if (questionNumber === (campaignInfo?.questions.length as number) - 1) {
      setNameCollectionModalOpen(true);
    } else {
      setQuestionNumber(questionNumber + 1);
    }
  }, [questionNumber, setQuestionNumber, campaignInfo]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => {
          const dub = [...prev];
          dub[questionNumber] = [data];
          return dub;
        });
      }
    },
    [setRecordedChunks, questionNumber]
  );

  const handleStart = useCallback(() => {
    setIsRecording(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [
    webcamRef,
    setIsRecording,
    mediaRecorderRef,
    setRecordedChunks,
    questionNumber,
  ]);

  const handleStartRecordingButtonPress = () => {
    const dub = [...recordedChunks];
    dub.splice(questionNumber, 1, null);
    setRecordedChunks(dub);
    setOverlayText("3");
    timeoutRef.current = setTimeout(() => {
      setOverlayText("2");
      timeoutRef.current = setTimeout(() => {
        setOverlayText("1");
        timeoutRef.current = setTimeout(() => {
          setOverlayText(null);
          handleStart();
          timeoutRef.current = null;
        }, 1000);
      }, 1000);
    }, 1000);
  };

  const handleInterupt = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setOverlayText(null);
  }, [timeoutRef]);

  const handleStop = useCallback(() => {
    mediaRecorderRef?.current?.stop?.();
    setIsRecording(false);
  }, [mediaRecorderRef, webcamRef, setIsRecording]);

  const submitVideo = useCallback(
    async (chunkArray?: (Blob | null)[]) => {
      const cachedQuestionNumber = questionNumber;
      const isFinalVideo =
        cachedQuestionNumber === (campaignInfo?.questions.length as number) - 1;

      goToNextQuestion(isFinalVideo);
      const arrayToUse =
        Array.isArray(chunkArray) && chunkArray.length
          ? chunkArray
          : recordedChunks[cachedQuestionNumber];
      if (arrayToUse.length) {
        let id = reviewId;
        if (!reviewId) {
          id = await datasource.createReviewId(campaignInfo?.id as number);
          setReviewId(id);
        }
        const blob = new Blob(arrayToUse, {
          type: mimeType,
        });

        if (isFinalVideo) {
          setFinalUploadStarted(true);
        }
        await datasource.submitVideo(
          campaignInfo?.id as number,
          id as number,
          campaignInfo?.questions[cachedQuestionNumber].id as number,
          blob
        );
        if (isFinalVideo) {
          const res = await datasource.sealVideo(
            campaignInfo?.id as number,
            id as number,
            campaignInfo?.questions[cachedQuestionNumber].id as number
          );
          if (res.ok) {
            setFinalUploadComplete(true);
          }
        }
      }
    },
    [recordedChunks, reviewId, questionNumber, goToNextQuestion]
  );

  const handleUploadChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.[0]) {
        const arrayBuffer = await e.target.files?.[0]?.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: "video/mp4" });
        setRecordedChunks((prev) => {
          const dub = [...prev];
          dub[questionNumber] = blob;
          return dub;
        });
        const dub = [...recordedChunks];
        dub[questionNumber] = blob;
        const isFinalVideo =
          questionNumber === (campaignInfo?.questions.length as number) - 1;
        if (isFinalVideo) {
          setFinalUploadStarted(true);
        }
        submitVideo([dub[dub.length - 1]]);
      }
    },
    [setRecordedChunks, submitVideo, questionNumber, pageNumber]
  );

  const handleIphoneVideo = useCallback(() => {
    videoInputRef.current?.click();
  }, [videoInputRef]);

  const buttons = useMemo(() => {
    if (isRecording) {
      return [
        <StandardButton buttonText="Stop Recording" onClick={handleStop} />,
      ];
    } else if (overLayText) {
      return [
        <StandardButton buttonText="Stop Recording" onClick={handleInterupt} />,
      ];
    } else if (hasRecordingForThisQuestion) {
      return [
        <StandardButton
          buttonText="Record Again"
          onClick={handleStartRecordingButtonPress}
        />,
        <StandardButton
          buttonText={
            questionNumber === (campaignInfo?.questions.length as number) - 1
              ? "Complete Review"
              : "Go To Next Question"
          }
          onClick={submitVideo}
        />,
      ];
    } else {
      return [
        <StandardButton
          buttonText="Begin Recording"
          onClick={handleStartRecordingButtonPress}
        />,
      ];
    }
  }, [
    isRecording,
    hasRecordingForThisQuestion,
    overLayText,
    handleStart,
    handleStop,
    handleInterupt,
    goToNextQuestion,
  ]);

  useEffect(() => {
    datasource
      .getRecorderData(param.id)
      .then((campaignInfo: RecorderCampaignInfo) => {
        if (campaignInfo.id) {
          setCampaignInfo(campaignInfo);
          setIsFirstLoading(false);
        }
      });
  }, []);

  const onToTheRecordingClick = useCallback(() => {
    setPageNumber(1);
    setQuestionNumber(0);
  }, [setPageNumber]);

  const toggleVideoPlaying = useCallback(() => {
    if (videoRef.current) {
      if (videoIsPlaying) {
        videoRef.current.pause();
        setVideoIsPlaying(false);
      } else {
        videoRef.current.play();
        setVideoIsPlaying(true);
      }
    }
  }, [videoIsPlaying, setVideoIsPlaying, videoRef]);

  return (
    <div style={{ width, height, backgroundColor: "#FFF8F0" }}>
      <RecorderContext.Provider
        value={{
          questionNumber,
          setQuestionNumber,
          isOpen: nameCollectionModalOpen,
          setIsOpen: setNameCollectionModalOpen,
          campaignInfo,
          reviewId,
          nameSectionComplete: nameCollectionComplete,
          setNameSectionComplete: setNameCollectionComplete,
        }}
      >
        <Drawer
          anchor="bottom"
          open={isFirstLoading || nameCollectionComplete}
          PaperProps={{ sx: { backgroundColor: "#FFF8F0" } }}
          transitionDuration={{ exit: 500, enter: 500 }}
        >
          <Loading
            isComplete={finalUploadComplete}
            completeText="Upload complete. Thank you"
            loadingText={
              !finalUploadComplete && finalUploadeStarted
                ? "Uploading Video. Do not close this tab"
                : ""
            }
          />
        </Drawer>
        <input
          type="file"
          id="videoInput"
          accept="video/mp4"
          capture="user"
          style={{ display: "none" }}
          ref={videoInputRef}
          onChange={handleUploadChange}
        ></input>
        <div
          style={{
            width,
            height,
            backgroundColor: "#FFF8F0",
            display: "flex",
            padding: "2rem",
            gap: "2rem",
            justifyContent: "center",
          }}
        >
          {!renderMobileLayout || !showRight ? (
            <RecorderLeftBar
              questionNumber={questionNumber}
              renderMobileLayout={renderMobileLayout}
              pageNumber={pageNumber}
              onToTheRecordingClick={onToTheRecordingClick}
              campaignInfo={campaignInfo}
              setShowRight={setShowRight}
              handleIphoneVideo={handleIphoneVideo}
            />
          ) : null}

          {!renderMobileLayout || showRight ? (
            <RecorderRightBar
              renderMobileLayout={renderMobileLayout}
              questionNumber={questionNumber}
              recordedChunks={recordedChunks}
              webcamRef={webcamRef}
              overLayText={overLayText}
              toggleVideoPlaying={toggleVideoPlaying}
              videoRef={videoRef}
              videoUrl={videoUrl}
              setVideoIsPlaying={setVideoIsPlaying}
              isRecording={isRecording}
              videoIsPlaying={videoIsPlaying}
              buttons={buttons}
            />
          ) : null}
        </div>
        <CollectNameModal />
      </RecorderContext.Provider>
    </div>
  );
};
