import { useCallback, useContext, useEffect, useState } from "react";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { CampaignModalContext } from "../Pages/dashboard";
import * as motion from "motion/react-client";
import { AnimatePresence } from "motion/react";
import { EditableTitle } from "./EditableTitle";
import { createOnChangeHandler } from "../lib/helpers";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  SortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DragAndDropQuestion } from "./DragAndDropQuestion";
import NumberCircle from "./NumberCirlcle";
import { StandardButton } from "./standardButton";
import { datasource } from "../datasource/datasource";
import useOrganziations from "../hooks/useOrganizations";
import { useAuth } from "@clerk/clerk-react";
import useCampaigns from "../hooks/useCampaigns";

export const CampaingModal = () => {
  const {getToken} = useAuth();
  const { width, height } = useScreenDimensions();
  const { isOpen, closeModal, campaignToEdit } =
    useContext(CampaignModalContext);

  const updateCampaign = useCampaigns(state => state.updateCampaign)
  const org = useOrganziations(state=>state.activeOrganization)

  const [campaignName, setCampaignName] = useState<string>(
    campaignToEdit?.name ?? "New Campaign"
  );
  const [items, setItems] = useState<string[]>(
    campaignToEdit?.questions.map((el) => el.text) ?? []
  );

  const onTitleChange = createOnChangeHandler(setCampaignName);

  const cancelEvent = (e: React.MouseEvent) => e.stopPropagation();

  useEffect(() => {
    setCampaignName(campaignToEdit?.name ?? "New Campaign");
    setItems(campaignToEdit?.questions.map((el) => el.text) ?? []);
  }, [campaignToEdit]);

  // Set up sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Start dragging after moving 5px
      },
    })
  );

  // Handle drag end
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(active.id);
        const newIndex = prevItems.indexOf(over.id);

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };

  const handleDelete = (index: number) => () => {
    const dup = [...items];
    dup.splice(index, 1);
    setItems(dup);
  };

  const [newQuestion, setNewQuestion] = useState<string>("");

  const onNewQuestionChange = createOnChangeHandler(setNewQuestion);
  const onBlur = useCallback(() => {
    setItems((prev) => [...prev, newQuestion]);
    setNewQuestion("");
  }, [setNewQuestion, newQuestion]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSaveChanges = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();
    const campaign = await datasource.editCampaign(campaignName, items, org?.id as number, campaignToEdit?.id as number, token as string);
    updateCampaign(campaign)
    closeModal();
    setIsLoading(false);
  }, [getToken, campaignToEdit, org, campaignName, items]);

  const createCampaign = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();
    const campaign = await datasource.addNewCampaign(campaignName, items, org?.id as number, token as string);
    updateCampaign(campaign)
    closeModal();
    setIsLoading(false)
  }, [getToken, items, org, campaignName])

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          key={"campaignModal"}
          style={{
            width,
            height,
            backgroundColor: "rgba(0, 0, 0, .3)",
            position: "fixed",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
          onClick={closeModal}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <div
            onClick={cancelEvent}
            style={{
              width: "75%",
              minWidth: 400,
              backgroundColor: "white",
              padding: 24,
              borderRadius: 16,
              opacity: 1,
            }}
          >
            <div style={{ marginBottom: "4rem" }}>
              <div>
                <EditableTitle
                  text={campaignName}
                  onUpdate={onTitleChange}
                  style={{
                    fontSize: 24,
                    fontWeight: 500,
                    marginBottom: "1rem",
                  }}
                />
              </div>
              <div>
                <span style={{ color: "#BCBCBC", fontSize: 14 }}>
                  Create new Campaign and put as many questions as you would
                  like
                </span>
              </div>
            </div>
            <hr />
            <div style={{ marginTop: "4rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <span>Questions</span>
              </div>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  <div>
                    {items.map((id, i) => (
                      <DragAndDropQuestion
                        key={id}
                        id={id}
                        questionNumber={i}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "1rem",
                  alignItems: "center",
                }}
              >
                <NumberCircle
                  number={"+"}
                  color="black"
                  style={{ fontSize: "1.25rem" }}
                />
                <input
                  style={{
                    borderRadius: 24,
                    backgroundColor: "#F7F7F7",
                    paddingBottom: ".5rem",
                    paddingTop: ".5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginLeft: ".5rem",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={newQuestion}
                  onChange={onNewQuestionChange}
                  onBlur={onBlur}
                  placeholder="Type Your Question Here"
                ></input>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "1rem",
              }}
            >
              <StandardButton
                buttonText="Cancel"
                onClick={closeModal}
                buttonColor="#F7F7F7"
                customStyles={{ color: "black", fontSize: "1.25rem" }}
              />
              <StandardButton
                isLoading={isLoading}
                buttonText={campaignToEdit ? "Save Changes" : "Create Campaign"}
                onClick={campaignToEdit ? onSaveChanges : createCampaign}
                customStyles={{ fontSize: "1.25rem" }}
              />
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
