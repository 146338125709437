import { useContext, useMemo } from "react";
import { Review } from "../types";
import { ReviewCard } from "./reviewCard";
import EmptyCampaign from '../assets/emptyCampaign.svg'
import { NewReviewModal } from "./newReviewModal";
import { ReviewFrontend } from "../syncedTypes";
import * as motion from "motion/react-client";
import { DashboardContext } from "../Pages/dashboard";

export interface DashboardContentSectionProps {
    reviews: ReviewFrontend[]
}

export const DashboardContentSection = ({reviews}: DashboardContentSectionProps) => {
    const reviewCards = useMemo(() => {
        return reviews.map(el => <ReviewCard review={el} key={el.id}/>)
    }, [reviews]);

    const {isExpanded} = useContext(DashboardContext)

    const staticContent = useMemo(() => {
        return (
            <div className="emptyCampaignGraphicContainer">
                <div className="centerAlginColumn">
                    <img src={EmptyCampaign} style={{height: '30rem', width: '30rem', overflow: 'hidden'}}/>
                    <p>No reviews added</p>
                </div>
            </div>
        )
    },[])
    return (
        <>{reviewCards.length ? (<motion.div layout
            transition={isExpanded ? { duration: 0 } : { duration: 1 }}
            style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            paddingRight: '2rem',
            paddingLeft: '3rem',
            paddingBottom: '4rem',
            gap: '1rem',
            flexDirection: isExpanded ? 'column' : 'row'
          }}>
            {reviewCards}
        </motion.div>) : staticContent}</>
    )
}