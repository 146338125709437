import { useEffect, useState } from "react";

function useRemSize(): number {
  const [remSize, setRemSize] = useState(() =>
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );

  useEffect(() => {
    const updateRemSize = () => {
      const newRemSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      setRemSize(newRemSize);
    };

    const observer = new ResizeObserver(() => {
      updateRemSize();
    });

    observer.observe(document.documentElement);

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);

  return remSize;
}

export default useRemSize;
