import useCampaigns from "../hooks/useCampaigns";

export const ThumbnailCircle = (props: {
  letter: string;
  index: number;
  remSize: number;
}) => {
  const allCampaigns = useCampaigns(state=> state.allCampaigns);
  let color;
  const correctedColorIndex = (allCampaigns.length - 1) - props.index
  let colorIndex = correctedColorIndex % 4;
  switch(colorIndex) {
    case 0:
      color = "#C84630";
      break;
    case 1:
      color = '#2EB3C8';
      break;
    case 2:
      color = '#BCBCBC';
      break;
    case 3:
      color = '#1F1F1F';
      break;
    default:
      color = "#C84630";
  }
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `${props.remSize}rem`,
        width: `${props.remSize}rem`,
        fontSize: `${props.remSize / 3.6}rem`
      }}
    >
      <span style={{color: 'white', opacity: .5, fontWeight: 600}}>{props.letter}</span>
    </div>
  );
};
