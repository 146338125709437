import { create } from 'zustand';
import { organizationFrontend, ReviewFrontend, User } from '../syncedTypes';

interface UserStore {
  user: User | null,
  setUser: (user: User) => void;
}

const useUser = create<UserStore>((set) => ({
  user: null,
  setUser: (user: User) => set({ user: user }),
}));

export default useUser;
