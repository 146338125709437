import React, { useCallback } from "react";
import './standardButton.css'
import { CircularProgress } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export interface StandarButtonProps {
    onClick: (e: any) => void;
    buttonText: string,
    buttonColor?: string,
    customStyles?: React.CSSProperties,
    disabled?: boolean,
    isLoading?: boolean,
    isBack?: boolean
}

export const StandardButton = React.memo(({onClick, buttonText, buttonColor, customStyles, disabled, isLoading, isBack}: StandarButtonProps) => {
    const style = {...buttonColor ? {backgroundColor: buttonColor} : {}}
    const stopPropOnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick(e);
    }, [onClick])

    let content;

    switch (true) {
        case isLoading:
            content = <CircularProgress />;
            break;
        case isBack:
            content = <KeyboardBackspaceIcon />;
            break;
        default:
            content = buttonText;
    }
    return (
        <button className="standardButton" onClick={stopPropOnClick} style={{minWidth: '15rem', minHeight: '3rem', fontSize: '1.5rem', opacity: (disabled ? .5 : 1), ...style, ...customStyles}} disabled={disabled}>
                {content}
        </button>
    )
})