import { createContext, useContext } from "react";

import useScreenDimensions from "../hooks/useScreenDimensions";

export interface Cords {
  x: number;
  y: number;
}

export const ContextMenuContext = createContext<{
  setOpen: (cords: Cords, options: JSX.Element[]) => void;
  closeMenu: () => void;
  isOpen: boolean;
  options: JSX.Element[];
  cords: Cords;
}>({
  setOpen: (cords: Cords, options: JSX.Element[]) => undefined,
  closeMenu: () => undefined,
  isOpen: false,
  options: [],
  cords: { x: 0, y: 0 },
});

export const ContextMenu = () => {
  const { isOpen, options, closeMenu, cords } = useContext(ContextMenuContext);
  const { width, height } = useScreenDimensions();

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(0,0,0,.3)",
        width,
        height,
        zIndex: 100,
      }}
      onClick={closeMenu}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#FFFFFF",
          width: "16rem",
          zIndex: 101,
          opacity: 1,
          top: cords.y,
          left: cords.x,
          borderRadius: 16,
          padding: 10
        }}
      >
        {options}
      </div>
    </div>
  );
};
