import * as motion from "motion/react-client";
import { useEffect, useState } from "react";
import checkmark from "../resources/check.svg";
import recordingIcon from "../resources/recordingIcon.svg";


export const RecorderPill = ({ isRecording }: { isRecording: boolean }) => {
  const [showAsRecording, setShowAsRecording] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (isRecording) {
      setShowAsRecording(true);
      setShowCompleted(true);
    } else {
      setShowAsRecording(false);
      setTimeout(() => setShowCompleted(false), 2500);
    }
  }, [isRecording]);

  let backgroundColor;
  let textColor;
  let text;
  let imgSource;

  if (showAsRecording) {
    backgroundColor = '#C84630';
    textColor = 'white';
    text = 'Recording';
    imgSource = recordingIcon;
  } else if (showCompleted) {
    backgroundColor = '#4BDD6D';
    textColor = 'black';
    text = 'Recording Complete';
    imgSource = checkmark;
  } else {
    backgroundColor = 'black';
    textColor = 'white';
    text = 'Not Recording'
    imgSource = null;
  }

  return (
    <motion.div
      layout
      style={{
        backgroundColor,
        color: textColor,
        borderRadius: 16,
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
        position: 'absolute',
        bottom: '1rem',
        left: '1rem',
        fontSize: '1.5rem',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '.5rem'
      }}
    >
      <div>{text}</div><div>{imgSource ? <img src={imgSource}/> : null}</div>
    </motion.div>
  );
};
