import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import NumberCircle from "./NumberCirlcle";
import trashIcon from "../resources/trashcan.svg";

export const DragAndDropQuestion = ({
  id,
  questionNumber,
  handleDelete
}: {
  id: string;
  questionNumber: number;
  handleDelete: (index: number) => () => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    justifyContent: "start",
    marginBottom: "1rem",
    alignItems: "center",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <NumberCircle number={questionNumber + 1} color="#2EB3C8" />
      <div
        style={{
          borderRadius: 24,
          backgroundColor: "#F7F7F7",
          paddingBottom: ".5rem",
          paddingTop: ".5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          marginLeft: ".5rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{id}</span>
        <div onClick={handleDelete(questionNumber)}><img src={trashIcon} /></div>
        
      </div>
    </div>
  );
};
