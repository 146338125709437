import { VideoPlayerContext } from "../../Pages/dashboard";
import { ReviewSegmentFrontend } from "../../syncedTypes";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import playButton from "../../resources/playbutton.svg";
import pauseButton from "../../resources/pausebutton.svg";
import nextButton from "../../resources/nexticon.svg";
import { Pill } from "../pill";
import * as motion from "motion/react-client";
import { VideoCardContext } from "../reviewCard";


type TimeUpdateEvent = HTMLMediaElementEventMap["timeupdate"];
type DurationLoaded = HTMLMediaElementEventMap["loadedmetadata"];
type Ended = HTMLMediaElementEventMap["ended"];

function formatTime(seconds: number): string {
  if (seconds < 0) {
      throw new Error("Seconds cannot be negative.");
  }

  seconds = Math.round(seconds);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds as "mm:ss"
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const VideoPlayer = ({
  segments,
}: {
  segments: ReviewSegmentFrontend[];
}) => {
  const segmentsHashesMap = useMemo(() => {
    const hashMap: { [index: string]: boolean } = {};
    segments.forEach((el) => {
      hashMap[el.hash] = true;
    });
    return hashMap;
  }, [segments]);
  const {currentVideoIndex, setCurrentVideoIndex, setSetVideoPlayingIndex} = useContext(VideoCardContext);
  const { videoPlayingHash, updatePlayingHash } =
    useContext(VideoPlayerContext);
  const videoPlayerRefs: React.RefObject<HTMLVideoElement>[] = useMemo(
    () => segments.map((el) => createRef()),
    [segments]
  );
  const [showControls, setShowControls] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [lengthOfCurrentVideo, setLengthOfCurrentVideo] = useState<number>(0);
  const [currentVideoProgress, setCurrentVideoProgress] = useState<number>(0);
  const [firstTimePlayed, setFirstTimePlayed] = useState<boolean>(false);
  const [numberLoadedMetaData, setNumberMetaData] = useState<number>(0);

  useEffect(() => {
    const addToHaveLoaded = () => setNumberMetaData((prev) => prev + 1);

    videoPlayerRefs.forEach(el => {
      const video: HTMLVideoElement = el.current as HTMLVideoElement;
      video.addEventListener('loadedmetadata', addToHaveLoaded);
    })

    return () => {
      videoPlayerRefs.forEach(el => {
        const video: HTMLVideoElement = el.current as HTMLVideoElement;
        video?.removeEventListener('loadedmetadata', addToHaveLoaded);
      })
    }
  }, []);

  const loadedAllMetaData = useMemo(() => numberLoadedMetaData === segments.length, [numberLoadedMetaData, segments]);

  const allVideoLength = useMemo(() => {
    if (loadedAllMetaData) {
      let num = 0;
      videoPlayerRefs.forEach(el => {
        const video = el.current as HTMLVideoElement;
        num = num + video.duration;
      });
      return num;
    } else {
      return null;
    }
    
  }, [numberLoadedMetaData])

  useEffect(() => {
    if (isPlaying) {
      setFirstTimePlayed(true);
    }
  }, [isPlaying]);
  const cleanUpFunctionRef = useRef<() => undefined>(() => undefined);

  const goToVideoIndex = useCallback((indexToGoTo: number) => {
    const nextIndex = indexToGoTo % (segments.length);
    updatePlayingHash(segments[nextIndex].hash);
    if (currentVideoIndex !== indexToGoTo) {
      setCurrentVideoIndex(indexToGoTo % segments.length);
      if ((videoPlayerRefs[nextIndex].current as HTMLVideoElement).ended) {
        (
          videoPlayerRefs[nextIndex].current as HTMLVideoElement
        ).currentTime = 0;
        setIsPlaying(false);
      } else {
        videoPlayerRefs[currentVideoIndex].current?.pause();
      }
      setIsPlaying(false);
    }


  }, [currentVideoIndex, segments, updatePlayingHash]);

  useEffect(() => {
    setSetVideoPlayingIndex(goToVideoIndex)
  }, [goToVideoIndex]);

  const goToNextVideo = useCallback(
    (e: React.MouseEvent | Ended) => {
      e.stopPropagation();
      const nextIndex = (currentVideoIndex + 1) % segments.length;
      goToVideoIndex(nextIndex);
    },
    [currentVideoIndex, segments]
  );

  const goToPreviousVideo = useCallback(
    (e: React.MouseEvent | Ended) => {
      e.stopPropagation();
      const prevIndex = Math.abs(currentVideoIndex - 1) % segments.length;
      goToVideoIndex(prevIndex);
    },
    [currentVideoIndex, segments]
  );

  const onHover = useCallback(() => setShowControls(true), [setShowControls]);
  const onMouseLeave = useCallback(
    () => setShowControls(false),
    [setShowControls]
  );

  const onClick = () => {
    const video: HTMLVideoElement = videoPlayerRefs[currentVideoIndex]
      .current as HTMLVideoElement;
    if (!isPlaying) {
      video.play();
      setIsPlaying(true);
      updatePlayingHash(segments[currentVideoIndex].hash);
    } else {
      video.pause();
      setIsPlaying(false);
      // updatePlayingHash("");
    }
  };

  useEffect(() => {
    cleanUpFunctionRef.current();
    videoPlayerRefs.map((el) => {
      if (el.current?.currentTime) {
        el.current.currentTime = 0;
      }
    });
    const video: HTMLVideoElement = videoPlayerRefs[currentVideoIndex]
      .current as HTMLVideoElement;
    const updateVideoProgress = (e: TimeUpdateEvent) =>
      // @ts-ignore
      setCurrentVideoProgress(e.target.currentTime);
    const updateVideoDuration = (e: DurationLoaded) =>
      // @ts-ignore
      setLengthOfCurrentVideo(Math.floor(e.target.duration));

    const onEnded = (e: Ended) => {
      goToNextVideo(e);
      const nextVideoIndex = Math.abs(currentVideoIndex + 1) % segments.length;
      if (nextVideoIndex) {
        const nextVideo: HTMLVideoElement = videoPlayerRefs[nextVideoIndex]
          .current as HTMLVideoElement;
        nextVideo.play();
        setIsPlaying(true);
      }
    };
    video.addEventListener("loadedmetadata", updateVideoDuration);
    video.addEventListener("timeupdate", updateVideoProgress);
    video.addEventListener("ended", onEnded);

    const cleanup = () => {
      video.removeEventListener("loadedmetadata", updateVideoDuration);
      video.removeEventListener("timeupdate", updateVideoProgress);
      video.removeEventListener("ended", onEnded);
      return undefined;
    };

    cleanUpFunctionRef.current = cleanup;

    if (video.duration) {
      setLengthOfCurrentVideo(Math.floor(video.duration));
    }

    setCurrentVideoProgress(video.currentTime);
  }, [currentVideoIndex, firstTimePlayed]);

  useEffect(() => {
    const video: HTMLVideoElement = videoPlayerRefs[currentVideoIndex]
      .current as HTMLVideoElement;
    if (isPlaying && !segmentsHashesMap[videoPlayingHash]) {
      setIsPlaying(false);
      video.pause();
    }
  }, [videoPlayingHash]);

  const scrubVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video: HTMLVideoElement = videoPlayerRefs[currentVideoIndex]
      .current as HTMLVideoElement;
    video.currentTime = parseInt(e.target.value);
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      style={{ position: "relative" }}
    >
      {segments.map((el, i) => {
        return (
          <video
            src={el.videoURL}
            controls={false}
            style={{
              display: currentVideoIndex === i ? "block" : "none",
              width: 358,
              height: 220,
              backgroundColor: "black",
              objectFit: "contain",
            }}
            // @ts-ignore
            ref={videoPlayerRefs[i]}
            key={el.videoURL}
          />
        );
      })}
      <motion.div
        animate={{opacity: showControls ? 1 : 0, height: showControls ? '1.5rem' : 0}}
        style={{
          display: showControls ? "flex" : "none", 
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "#1F1F1F",
          justifyContent: "space-between",
          paddingLeft: ".2rem",
          paddingRight: ".2rem",
        }}
      >
        <img src={isPlaying ? pauseButton : playButton} />
        <img
          src={nextButton}
          style={{ transform: "rotate(180deg)" }}
          onClick={goToPreviousVideo}
        />
        <img src={nextButton} onClick={goToNextVideo} />
        <input
          onInput={scrubVideo}
          type={"range"}
          value={currentVideoProgress}
          min={0}
          max={lengthOfCurrentVideo}
          style={{ accentColor: "#2EB3C8", width: "70%" }}
        />
        <span style={{ color: "white" }}>{`${currentVideoIndex + 1} / ${
          segments.length
        }`}</span>
      </motion.div>
      <div
        style={{
          position: "absolute",
          display: isPlaying ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 83,
          left: 152,
          width: 54,
          height: 54,
        }}
      >
        <img src={playButton} />
      </div>
      <div
        style={{
          position: "absolute",
          display: !isPlaying && !showControls ? "flex" : "none",
          bottom: "1rem",
          right: "1rem",
        }}
      >
        <Pill backgroundColor="#1F1F1F" textColor="white" text={allVideoLength ? formatTime(allVideoLength) : '0'} style={{fontSize: 13.5, opacity: allVideoLength ? 1 : 0}} />
      </div>
    </div>
  );
};
