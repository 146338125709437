import { Link } from "react-router-dom";
import useScreenDimensions from "../hooks/useScreenDimensions"

export const PlanChoicePage = () => {
  const {height, width} = useScreenDimensions();
  return (
    <div style={{height, width, backgroundColor: '#F6F2EC'}}>
      <Link to={'/payment/lite'}>Lite Plan</Link>
      <Link to={'/payment/full'}>Full Plan</Link>
    </div>
  )
}