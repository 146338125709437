import { useCallback, useEffect, useRef, useState } from "react";
import pencil from "../resources/pencil.svg";

export const EditableTitle = ({
  text,
  onUpdate,
  style,
}: {
  text: string;
  onUpdate: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  style?: React.CSSProperties;
}) => {
  const textAreaRef = useRef<null | HTMLInputElement>(null);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if(isEditable) {
      textAreaRef.current?.focus();
    }
  }, [isEditable]);

  return (
    <div style={style}>
      {isEditable ? (
        <input
          ref={textAreaRef}
          value={text}
          onChange={onUpdate}
          onBlur={() => setIsEditable(false)}
        />
      ) : (
        <div style={{display: 'flex', gap: '1rem'}} onDoubleClick={() => setIsEditable(true)}>
          <span>{text}</span>
          <img src={pencil} style={{cursor: 'pointer'}} onClick={() => setIsEditable(true)} />
        </div>
      )}
    </div>
  );
};
