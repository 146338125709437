import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

const publishableKey =
  "pk_test_51QAb3iGTWCxfv4CSDx6bx1si5vDTA0hHMXQsxrb5OS2IDiXLEI8zwsnXVvHUtaNSz5UC8kfJNbFyfblsT446kWs800MhPbogoC";

const stripePromise = loadStripe(publishableKey);

export const PaymentPage = () => {
  const {type} = useParams();
  const {getToken} = useAuth();
  const fetchClientSecret = useCallback(async () => {
    const token = await getToken();
    return type === 'lite' ? datasource.getStripeSessionSecretForLitePackage(token as string) : datasource.getStripeSessionSecretForFullPackage(token as string)
  }, [getToken, datasource]);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}