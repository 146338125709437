import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";
import { StandardButton } from "../components/standardButton";
import NumberCircle from "../components/NumberCirlcle";
import { RecorderCampaignInfo } from "./recorder";
import { useCallback } from "react";
import { isIOS } from "react-device-detect";

const directions = [
  "Ensure you are in a place with minimum background noise",
  "Ensure your camera will remain steady",
  "Be sure there is plenty of light",
];

const DirectionPoint = ({
  text,
  num,
  isHighlighted,
  allHighlighted,
  renderMobileLayout
}: {
  text: string;
  num: number;
  isHighlighted?: boolean;
  allHighlighted?: boolean;
  renderMobileLayout?: boolean;
}) => {
  const opacity = !allHighlighted && !isHighlighted ? 0.2 : 1;
  return (
    <motion.div
      layout
      style={{
        display: "flex",
        justifyContent: "start",
        gap: renderMobileLayout ? ".5rem" : "1rem",
        position: "relative",
        padding: ".25rem",
        opacity,
      }}
    >
      <NumberCircle
        number={num}
        color={"#2EB3C8"}
        style={{
          fontSize: renderMobileLayout ? ".9rem" : "1rem",
          width: "2rem",
          height: "2rem",
          minWidth: "2rem",
          minHeight: "2rem",
        }}
      />
      <div style={{ fontSize: renderMobileLayout ? ".9rem" : "1rem" }}>
        {text}
      </div>
      {!allHighlighted && isHighlighted ? (
        <motion.div
          layoutId={`selectedQuestion`}
          style={{
            borderRadius: 10,
            opacity: 0.3,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0)",
            pointerEvents: "none",
          }}
        />
      ) : null}
    </motion.div>
  );
};

export const RecorderLeftBar = ({
  questionNumber,
  renderMobileLayout,
  pageNumber,
  campaignInfo,
  onToTheRecordingClick,
  setShowRight,
  handleIphoneVideo
}: {
  questionNumber: number;
  renderMobileLayout: boolean;
  pageNumber: number;
  campaignInfo: RecorderCampaignInfo | null;
  onToTheRecordingClick: () => void;
  setShowRight: (showRight: boolean) => void,
  handleIphoneVideo: () => void;

}) => {
  const goToRight = useCallback(() => setShowRight(true), [setShowRight]);

  const handleAnswerQuestionClick = () => {
    // isIOS
    if (false) {
      handleIphoneVideo();
    } else {
      goToRight();
    }
  }
  return (
    <div
      style={{
        width: !renderMobileLayout ? "40%" : "100%",
        backgroundColor: "white",
        borderRadius: 16,
        padding: "1rem",
      }}
    >
      <img src={"/logo.svg"} />
      <AnimatePresence>
        {!pageNumber ? (
          <motion.div
            layoutId={"initial"}
            key={"initial"}
            style={{ position: "relative" }}
            exit={{ left: -250, opacity: 0 }}
          >
            <div
              style={{
                marginTop: "2rem",
                fontSize: renderMobileLayout ? "1.5rem" : "2rem",
                fontWeight: 500,
              }}
            >
              Thank you for reviewing {campaignInfo?.orgName}
            </div>
            <div
              style={{
                marginTop: "2rem",
                fontSize: renderMobileLayout ? ".8rem" : ".9rem",
              }}
            >
              To make sure that your review will be of high quality, please pay
              attention to the following points:
            </div>
            <div
              style={{
                marginTop: "1.5rem",
                paddingTop: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                marginBottom: "2rem",
              }}
            >
              {directions.map((el, i) => {
                return (
                  <DirectionPoint
                    renderMobileLayout={renderMobileLayout}
                    text={el}
                    num={i + 1}
                    key={el}
                    allHighlighted
                  />
                );
              })}
            </div>
            <StandardButton
              buttonText="To the Recording"
              buttonColor="#2EB3C8"
              onClick={onToTheRecordingClick}
            />
          </motion.div>
        ) : (
          <motion.div
            style={{ opacity: 1, position: "relative", left: 0 }}
            initial={{ opacity: 0, left: 250 }}
            animate={{ opacity: 1, left: 0 }}
            key={"questions"}
            layoutId={"quesitons"}
          >
            <div
              style={{
                marginTop: "2rem",
                fontSize: "2rem",
                fontWeight: 500,
              }}
            >
              Please answer the highlighted question:
            </div>
            <div
              style={{
                marginTop: "1.5rem",
                paddingTop: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                marginBottom: "2rem",
              }}
            >
              {campaignInfo?.questions.map((el, i) => {
                return (
                  <DirectionPoint
                    renderMobileLayout={renderMobileLayout}
                    text={el.text}
                    num={i + 1}
                    key={el.text}
                    isHighlighted={i === questionNumber}
                  />
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {renderMobileLayout && pageNumber ? <StandardButton onClick={handleAnswerQuestionClick} buttonText={'Answer Question'} /> : null}
    </div>
  );
};
