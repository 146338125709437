import { CampaignFrontend } from "../syncedTypes";
import { Campaign } from "../types";
import { ThumbnailCircle } from "./ThumbnailCircle";
import tripleDot from "../resources/tripledot.svg";
import './sidebarItem.css'
import { useCallback, useContext, useMemo } from "react";
import { ContextMenuContext } from "./ContextMenu";
import { ContextMenuItem } from "./ContextMenuItem";
import copyIcon from '../resources/copyIcon.svg';
import pencilIcon from '../resources/pencil.svg';
import activate from '../resources/onicon.svg';
import trashcan from '../resources/trashcan.svg'
import useCampaigns from "../hooks/useCampaigns";
import { datasource } from "../datasource/datasource";
import useOrganziations from "../hooks/useOrganizations";
import { useAuth } from "@clerk/clerk-react";
import { CampaignModalContext, DashboardContext, DeleteCampaignModalContext } from "../Pages/dashboard";

export const SidebarItem = ({
  campaign,
  index,
}: {
  campaign: CampaignFrontend;
  index: number;
}) => {
  const { openSnackBar } = useContext(DashboardContext);
  const {getToken} = useAuth();
  const org = useOrganziations(state => state.activeOrganization);
  const setActiveCampaign = useCampaigns(state => state.setSelectedCampaign);
  const updateCampaign = useCampaigns(state => state.updateCampaign);
  const {setOpen} = useContext(ContextMenuContext);
  const {setCampaignId, setIsOpen} = useContext(DeleteCampaignModalContext);
  const openDeleteModal = useCallback(() => {
    setCampaignId(campaign.id);
    setIsOpen(true);
  }, [setCampaignId, setIsOpen])
  const onCopyPress = (event: React.MouseEvent) => {
    window.navigator.clipboard.writeText(window.location.origin + "/c/" + campaign.hash);
    openSnackBar();
  };
  const onClick = () => {
    setActiveCampaign(campaign)
  }
  const {setOpenEditCampaign} = useContext(CampaignModalContext)

  const openModal = useCallback(() => {
    setOpenEditCampaign(campaign)
  }, [setOpenEditCampaign, campaign])

  const dummyOnclick = () => console.log('Click')
  const toggleCampaignIsActive = async () => {
    const token = await getToken();
    const newData = await datasource.setCampaignActiveStatus(campaign.id, org?.id as number, !campaign.isActive, token as string);
    updateCampaign(newData)
  }
  const contextMenuOptions = useMemo(() => {
    return [
      <ContextMenuItem text="Copy Review Link" icon={copyIcon} onClick={onCopyPress}/>,
      <ContextMenuItem text="Edit Question" icon={pencilIcon} onClick={openModal}/>,
      <ContextMenuItem text={campaign.isActive ? "Deactivate" : "Activate"} icon={activate} onClick={toggleCampaignIsActive}/>,
      <ContextMenuItem text="Delete Campaign" icon={trashcan} onClick={openDeleteModal}/>,
    ]
  }, [campaign])
  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen({x: e.pageX, y: e.pageY}, contextMenuOptions)
  }
  return (
    <div
      style={{
        height: "5.2rem",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        paddingLeft: ".5rem",
        cursor: 'pointer'
      }}
      className="sidebarItem"
      onContextMenu={onContextMenu}
      onClick={onClick}
    >
      <ThumbnailCircle
        index={index}
        letter={campaign.name[0].toUpperCase()}
        remSize={4}
      />
      <div
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: 8
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {campaign.name}
            <div
              style={{
                borderRadius: "50%",
                width: ".5rem",
                height: ".5rem",
                backgroundColor: campaign.isActive ? "#4BDD6D" : "#C84630",
                marginLeft: '.5rem'
              }}
            />
          </div>
          <div onClick={onContextMenu}>
            <img src={tripleDot} />
          </div>
        </div>
        <div style={{fontSize: '.75rem', color: '#899091'}}>{campaign.reviews.length} reviews recorded</div>
      </div>
    </div>
  );
};
