//  /* Frame 9 */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 12px 16px;
// gap: 36px;

// width: 693px;
// height: 36px;

// /* Light Gray */
// background: #F7F7F7;
// border-radius: 24px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// align-self: stretch;
// flex-grow: 0;

const basicInputStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingTop: ".75rem",
  paddingBottom: ".75rem",
  gap: 36,
  backgroundColor: "#F7F7F7",
  borderRadius: 24,
  marginTop: '.5rem',
  width: '100%'
};
export const StandardInput = (props: {
  label: string;
  styles?: React.CSSProperties;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeHolder?: string;
  value: string;
}) => {
  const { label, onChange, styles, placeHolder, value } = props;
  return (
    <div style={{width: '100%'}}>
      <span style={{paddingLeft: '.5rem'}}>{label}</span>
      <input style={{...basicInputStyles, ...styles}} onChange={onChange} placeholder={placeHolder} value={value} />
    </div>
  );
};
