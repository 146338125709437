import { MarginRounded } from '@mui/icons-material';
import React from 'react';

function NumberCircle({ number, color, textColor, style}: {number: number | string, color?: string, textColor?: string, style?: React.CSSProperties}) {
  const circleStyle = {
    width: '1.5rem',
    height: '1.5rem',
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    borderRadius: '50%',
    backgroundColor: color ?? '#3498db', // Change this to any color you like
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: textColor ?? '#fff', // Text color
    fontSize: '.75rem', // Adjust the font size as needed
    marginRight: '.5rem',
    cursor: typeof number === 'string' ? 'pointer' : 'grab',
    ...style
  };

  return (
    <div style={circleStyle}>
      {number}
    </div>
  );
}

export default NumberCircle;