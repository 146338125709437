import React from "react";
import './ContextMenuItem.css'

export const ContextMenuItem = ({
  icon,
  text,
  onClick,
}: {
  icon: any;
  text: string;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <div style={{ width: "100%", height: "3rem", display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} className={'ContextMenuItem'} onClick={onClick}>
      <div style={{display: 'flex'}}>
        <img src={icon} style={{ height: "1.2rem", width: "1.2rem" }} />{" "}
        <span style={{marginLeft: '.5rem'}}>{text}</span>
      </div>
      <div
        className="line"
        style={{
          width: "100%",
          marginLeft: 4,
          marginRight: 4,
          height: 1,
        }}
      />
    </div>
  );
};
