import { AnimatePresence } from "motion/react";
import { useContext } from "react";
import * as motion from "motion/react-client";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { DeleteCampaignModalContext } from "../Pages/dashboard";
import { StandardButton } from "./standardButton";
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import useOrganziations from "../hooks/useOrganizations";
import useCampaigns from "../hooks/useCampaigns";

export const DeleteCampaignModal = () => {
  const { isOpen, setIsOpen, campaignId, setCampaignId } =
    useContext(DeleteCampaignModalContext);

  const {getToken} = useAuth();

  const org = useOrganziations(state => state.activeOrganization);

  const deleteCampaign = useCampaigns(state => state.deleteCampaign)

  const { width, height } = useScreenDimensions();

  const cancelEvent = (e: React.MouseEvent) => e.stopPropagation();

  const closeModal = () => {
    setIsOpen(false);
    setCampaignId(0);
  }

  const deleteCampaignPress = async () => {
    const token = await getToken();
    const p = datasource.deleteCampaign(org?.id as number, campaignId, token as string);
    setIsOpen(false);
    await p;
    deleteCampaign(campaignId);
    setCampaignId(0);
  }


  return (

    <AnimatePresence>
      {isOpen ? (
        <motion.div
          key={"campaignModal"}
          style={{
            width,
            height,
            backgroundColor: "rgba(0, 0, 0, .3)",
            position: "fixed",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
          onClick={closeModal}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <div
            onClick={cancelEvent}
            style={{
              width: "40rem",
              minWidth: 400,
              backgroundColor: "white",
              padding: 24,
              borderRadius: 16,
              opacity: 1,
            }}
          >
            <div style={{fontSize: '1.25rem', marginBottom: '.5rem'}}>Are you sure you want to delete this campaign?</div>
            <div style={{marginBottom: '2rem'}}>All related reviews and videos will be deleted</div>
            <div style={{display: 'flex', justifyContent: 'space-around', gap: '2rem'}}><StandardButton
                buttonText="Cancel"
                onClick={closeModal}
                buttonColor="#F7F7F7"
                customStyles={{ color: "black", fontSize: "1.25rem" }}
              />
              <StandardButton
                buttonText={'Delete Campaign'}
                onClick={deleteCampaignPress}
                customStyles={{ fontSize: "1.25rem" }}
              /> </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
