import { AnimatePresence } from "motion/react";
import { createContext, useCallback, useContext, useState } from "react";
import * as motion from "motion/react-client";
import useScreenDimensions from "../hooks/useScreenDimensions";
import { RecorderContext } from "../Pages/recorder";
import { datasource } from "../datasource/datasource";
import { createOnChangeHandler } from "../lib/helpers";
import { StandardInput } from "./standardInput";
import { StandardButton } from "./standardButton";

export const CollectNameModal = () => {
  const { isOpen, setIsOpen, campaignInfo, reviewId, setNameSectionComplete } =
    useContext(RecorderContext);

  const { width, height } = useScreenDimensions();

  const cancelEvent = (e: React.MouseEvent) => e.stopPropagation();

  const closeModal = () => {
    // setIsOpen(false);
  };

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const updateName = createOnChangeHandler(setName);
  const updateEmail = createOnChangeHandler(setEmail);

  const saveName = useCallback(async () => {
    setNameSectionComplete(true);
    await datasource.setReviewerName(
      campaignInfo?.id as number,
      reviewId,
      name,
      email
    );
  }, [campaignInfo, name, email, reviewId]);

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          key={"campaignModal"}
          style={{
            width,
            height,
            backgroundColor: "rgba(0, 0, 0, .75)",
            position: "fixed",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
          onClick={closeModal}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <div
            onClick={cancelEvent}
            style={{
              width: "40rem",
              minWidth: 400,
              backgroundColor: "white",
              padding: 24,
              borderRadius: 16,
              opacity: 1,
            }}
          >
            <div style={{fontWeight: 500, fontSize: '1.5rem', paddingBottom: '2rem'}}>Thank you for recording you review</div>
            <div style={{paddingBottom: '2rem'}}>Last question before you go:</div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
              <StandardInput
                label="Your Name"
                onChange={updateName}
                placeHolder="Name"
                value={name}
              />
              <StandardInput
                label="Email"
                onChange={updateEmail}
                placeHolder="Email"
                value={email}
              />
              <StandardButton buttonText="Finish Review" onClick={saveName} />
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
