import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./ReviewCard.css"; // Import the CSS file for styling
import { datasource } from "../datasource/datasource";
import { useAuth } from "@clerk/clerk-react";
import { createOnChangeHandler, getOverRides } from "../lib/helpers";
import { ReviewFrontend } from "../syncedTypes";
import { useNavigate, useNavigation } from "react-router-dom";
import useSelectedReview from "../hooks/useSelectedReview";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";
import { Tag } from "react-tag-input/types/components/SingleTag";
import { DashboardContext, VideoPlayerContext } from "../Pages/dashboard";
import useCampaigns from "../hooks/useCampaigns";
import useOrganziations from "../hooks/useOrganizations";
import { VideoPlayer } from "./videoComponent/videoPlayer";
import { Pill } from "./pill";
import tripleDot from "../resources/tripledot.svg";
import whiteTripleDot from "../resources/whitetripledot.svg";
import { ContextMenuItem } from "./ContextMenuItem";
import { ContextMenuContext } from "./ContextMenu";
import downloadIcon from "../resources/downloadicon.svg";
import zipIcon from "../resources/zip.svg";
import expandIcon from "../resources/expand.svg";
import trashIcon from "../resources/trashcan.svg";
import * as motion from "motion/react-client";
import { AnimatePresence } from "motion/react";
import NumberCircle from "./NumberCirlcle";
import useRemSize from "../hooks/useRemValue";

const convertDateToString = (dateAsNumber: number) => {
  const date = new Date(dateAsNumber);
  // Add commas to spaces
  let spaceCount = 0;
  const dateString = date
    .toDateString()
    .split("")
    .map((el) => {
      if (el === " ") {
        spaceCount++;
        if (spaceCount !== 2) {
          return ", ";
        }
      }
      return el;
    });

  return dateString.join("");
};

export const VideoCardContext = React.createContext<{
  currentVideoIndex: number;
  setCurrentVideoIndex: (newIndex: number) => void;
  setSetVideoPlayingIndex: (setVideoPlayingIndex: (num: number) => void) => void,
}>({ currentVideoIndex: 0, setCurrentVideoIndex: (num: number) => undefined, 
  setSetVideoPlayingIndex: (setVideoPlayingIndex: ((num: number) => void)) => undefined});

export const ReviewCard = ({ review }: { review: ReviewFrontend }) => {
  const {getToken} = useAuth();
  const { videoPlayingHash } =
      useContext(VideoPlayerContext);
  const org = useOrganziations(state => state.activeOrganization);
  const activeCampaign = useCampaigns(state => state.selectedCampaign);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const setVideoPlayingIndex = useRef<(num: number) => void>((num: number) => console.log('setSetIsbeing called'));
  const setSetVideoPlayingIndex = (newFunc: (num: number) => void) => {setVideoPlayingIndex.current = newFunc};
  const { setIsExpanded, isExpanded } = useContext(DashboardContext);
  const { setOpen } = useContext(ContextMenuContext);
  const remSize = useRemSize();
  const [animationIsTriggered, setAnimationIsTriggered] =
    useState<boolean>(false);
  const [notes, setNotes] = useState<string>(review.notes ?? '');

  const updateNotes = createOnChangeHandler(setNotes);

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => setAnimationIsTriggered(true), 500);
    } else {
      setAnimationIsTriggered(false);
    }
  }, [isExpanded]);
  const dateString = useMemo(() => {
    return convertDateToString(review.dateRecorded);
  }, [review]);

  const dummyOnclick = () => console.log("Click");

  const setToExpanded = () => setIsExpanded(true);

  const contextMenuOptions = useMemo(() => {
    const menu = [
      <ContextMenuItem
        text="Download All As Zip"
        icon={zipIcon}
        onClick={dummyOnclick}
      />,
    ];
    if (!isExpanded) {
      menu.push(
        <ContextMenuItem
          text="Expand Review"
          icon={expandIcon}
          onClick={setToExpanded}
        />
      );
    }
    return menu;
  }, [review, isExpanded]);

  const expandedContextMenuOptions = useMemo(() => {
    return [
      <ContextMenuItem
        text="Download Current Video"
        icon={downloadIcon}
        onClick={dummyOnclick}
      />,
      <ContextMenuItem
        text="Download All As Zip"
        icon={zipIcon}
        onClick={dummyOnclick}
      />,
      <ContextMenuItem
        text="Delete Review"
        icon={trashIcon}
        onClick={dummyOnclick}
      />,
    ];
  }, [review]);

  const onMainContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen({ x: e.pageX, y: e.pageY }, contextMenuOptions);
  };

  const onExpandedContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen(
      { x: e.pageX - 16 * remSize, y: e.pageY },
      expandedContextMenuOptions
    );
  };

  const goToReviewExpanded = () => {
    setIsExpanded(true);
  };

  const onQuestionClickGenerator = (index: number) => (e: React.MouseEvent) => setVideoPlayingIndex.current(index);

  const onNotesBlur = useCallback(async () => {
    const token = await getToken();
    datasource.updateNotes(org?.id as number, activeCampaign?.id as number, review.id, notes, token as string)
  }, [org, activeCampaign, review, notes, getToken]);

  return (
    <VideoCardContext.Provider
      value={{ currentVideoIndex, setCurrentVideoIndex, setSetVideoPlayingIndex }}
    >
      <motion.div
        layout
        style={{
          width: animationIsTriggered && isExpanded ? "100%" : 358,
          display: "flex",
          justifyContent: "start",
        }}
      >
        <motion.div
          layout
          layoutId={review.hash}
          whileHover={{ scale: 1.05, width: 1.05 * 358 }}
          whileTap={{ scale: 1.03 }}
          style={{
            boxShadow: "0px 0px 15px 0px #c3c3c3",
            backgroundColor: "#FFFFFF",
            marginBottom: "1rem",
            cursor: "pointer",
            width: 358,
            minWidth: 358,
            borderRadius: 16
          }}
        >
          <VideoPlayer segments={review.segments} />
          <div
            style={{
              height: "7rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
            onClick={goToReviewExpanded}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 style={{ fontSize: 24, fontWeight: 500 }}>{review.name}</h2>
              </div>
              <div onClick={onMainContextMenu} style={{ cursor: "pointer" }}>
                <img src={tripleDot} />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Pill
                  text="2 Videos Recorded"
                  textColor="white"
                  backgroundColor="#C84630"
                  style={{
                    fontSize: 14,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                />
                <p
                  style={{
                    color: "Gray",
                    fontWeight: 400,
                    fontSize: 14,
                    marginLeft: "1rem",
                  }}
                >
                  {dateString}
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          layout
          style={{
            display: isExpanded && animationIsTriggered ? "flex" : "none",
            opacity: isExpanded && animationIsTriggered ? 1 : 0,
            justifyContent: "space-between",
            width: "100%",
            marginLeft: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              marginRight: "1rem",
            }}
          >
            <span
              style={{ marginBottom: "1rem", fontWeight: 500, fontSize: 16 }}
            >
              Questions
            </span>
            {review.segments.map((el, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "1rem",
                    position: "relative",
                    cursor: 'pointer'
                  }}
                  key={el.hash}
                  onClick={onQuestionClickGenerator(i)}
                >
                  <NumberCircle number={i + 1} color="#2EB3C8" />
                  <span style={{ fontSize: ".8rem" }}>{el.question.text}</span>
                  {el.hash === videoPlayingHash ? <motion.div
                    layoutId={`selectedQuestion ${review.id}`}
                    style={{
                      borderRadius: 10,
                      opacity: .3,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0)",
                      pointerEvents: "none"
                    }}
                  /> : null}
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "start",
              marginRight: "1rem",
            }}
          >
            <span style={{ fontSize: "1rem", marginBottom: "1rem" }}>
              Notes
            </span>
            <textarea
              onBlur={onNotesBlur}
              style={{
                width: 296,
                height: 183,
                backgroundColor: "#F7F7F7",
                borderRadius: 24,
                padding: 12,
              }}
              placeholder="Leave any notes here"
              onChange={updateNotes}
              value={notes}
            ></textarea>
          </div>
          <div
            style={{
              minWidth: 36,
              minHeight: 36,
              width: 36,
              height: 36,
              borderRadius: "50%",
              backgroundColor: "#2EB3C8",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={onExpandedContextMenu}
          >
            <img src={whiteTripleDot} />
          </div>
        </motion.div>
      </motion.div>
    </VideoCardContext.Provider>
  );
};
