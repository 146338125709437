import { create } from 'zustand';
import { CampaignFrontend, ReviewFrontend } from '../syncedTypes';

interface CampaignStore {
  selectedCampaign: CampaignFrontend | null;
  allCampaigns: CampaignFrontend[],
  setSelectedCampaign: (campaign: CampaignFrontend) => void;
  setCampaigns: (campaigns: CampaignFrontend[]) => void;
  setCampaignToViewAll: () => void,
  updateCampaign: (campaign: CampaignFrontend) => void;
  deleteCampaign: (campaignId: number) => void;
}

const useCampaigns = create<CampaignStore>((set) => ({
  selectedCampaign: null,
  allCampaigns: [],
  setSelectedCampaign: (campaign: CampaignFrontend) => set({ selectedCampaign: campaign }),
  setCampaigns: (campaigns: CampaignFrontend[]) => set({ allCampaigns: campaigns }),
  setCampaignToViewAll: () => set((state) => {
    const reviews: ReviewFrontend[] = [];
    state.allCampaigns.forEach(el => {
      el.reviews.forEach(el => {
        reviews.push(el);
      })
    });
    reviews.sort((a,b) => b.dateRecorded - a.dateRecorded);
    return {selectedCampaign: {
    id: 0,
    hash: '',
    dateCreated: 0,
    isActive: false,
    questions: [],
    name: 'All Reviews',
    reviews: reviews
  }}}),
  updateCampaign: (campaign: CampaignFrontend) => {
    set((state) => {
      const allCurrentCampaigns = [...state.allCampaigns];
      const indexOfCampaignToUpdate = allCurrentCampaigns.findIndex(el => el.id === campaign.id);
      if (indexOfCampaignToUpdate === -1) {
          allCurrentCampaigns.unshift(campaign);
      } else {
          allCurrentCampaigns[indexOfCampaignToUpdate] = campaign;
      }
      return {
        allCampaigns: allCurrentCampaigns,
        selectedCampaign: campaign
      }
    });
  },
  deleteCampaign: (campaignId: number) => {
    set((state) => {
      const allCurrentCampaigns = [...state.allCampaigns];
      const filtered = allCurrentCampaigns.filter(el => el.id !== campaignId);
      return {
        allCampaigns: filtered,
        selectedCampaign: filtered[0]
      }
    })
  }
    
}));

export default useCampaigns;